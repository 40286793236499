
import { Options, Prop, Vue, Watch } from "vue-property-decorator";

@Options({})
export default class extends Vue {
  @Prop({ required: true })
  private options: any;

  private aliplayer: any;

  @Watch("options", { deep: true })
  private onOptionsChange(): void {
    if (this.aliplayer) {
      this.aliplayer.dispose();
      setTimeout(() => {
        this.createAliplayer();
      }, 1000);
    }
  }

  mounted(): void {
    this.createAliplayer();
  }

  beforeUnmount(): void {
    if (this.aliplayer) {
      this.aliplayer.dispose();
    }
  }

  private createAliplayer(): void {
    this.aliplayer = new Aliplayer(
      {
        id: "J_prismPlayer",
        ...this.options,
      },
      (player: any) => {
        player.setVolume(0); //音乐调到零
        player.play();
        this.$emit("aliplayerReady", player);
      }
    );
  }
}
